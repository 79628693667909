import React from 'react';

import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    greenHr: {
        borderTop: `7px solid ${theme.palette.primary.main}`,
        marginLeft: "15%",
        marginRight: "15%",
        borderBottom: "none",
        borderLeft: "none",
        borderRight: "none",
    },
}));

function GreenHr() {
    const classes = useStyles()
    return <hr size={30} className={classes.greenHr}/>
}

export default GreenHr;