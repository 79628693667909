import React from 'react'
import PropTypes from "prop-types"
import classNames from "classnames";

import {makeStyles} from "@material-ui/core/styles"
import Grid from "@material-ui/core/Grid";
import {Typography} from "@material-ui/core";

import {hexToRgb} from "../../../util/hexToRgb";

const useStyles = makeStyles(theme => ({
    parallax: {
        height: "35vh",
        overflow: "hidden",
        position: "relative",
        backgroundPosition: "50%",
        backgroundSize: "cover",
        display: "flex",
        alignItems: "center",
        boxShadow: theme.shadows[3],
        [theme.breakpoints.down('sm')]: {
            height: "55vh",
        }
    },
    darkColor: {
        "&:before": {
            background: "rgba(" + hexToRgb(theme.palette.common.black) + ", 0.5)"
        },
        "&:after,&:before": {
            position: "absolute",
            zIndex: "1",
            width: "100%",
            height: "100%",
            display: "block",
            left: "0",
            top: "0",
            content: "''"
        }
    },
    headerText: {
        color: theme.palette.common.white,
        textTransform: "uppercase",
        marginBottom: theme.spacing(5)
    },
    bodyText: {
        color: theme.palette.common.white,
        zIndex: "5",
        [theme.breakpoints.down("xs")]: {
            marginBottom: theme.spacing(2),
        }
    },
    wordBlock: {
        position: "absolute",
    },
    container: {
        zIndex: "2"
    },
}))

export default function CommonInjuriesBlock({image, header, body}) {
    const classes = useStyles()
    const parallaxClasses = classNames({
        [classes.parallax]: true,
        [classes["darkColor"]]: true,
    });

    return (
        <div className={parallaxClasses} style={{backgroundImage: `url(${image})`}}>
            <div className={classes.container}>
                <Typography
                    variant={"h4"}
                    align={"center"}
                    className={classes.headerText}
                >
                    <strong>
                        {header}
                    </strong>
                </Typography>
                <Grid
                    container
                    justify={"space-between"}
                >
                    {body.map((ele, key) =>
                        <Grid md={3} sm={4} xs={5} item key={key}>
                            <Typography
                                variant={"subtitle1"}
                                align={"center"}
                                className={classes.bodyText}
                                gutterBottom
                            >
                                {ele}
                            </Typography>
                        </Grid>
                    )}
                </Grid>
            </div>
        </div>
    )
}

CommonInjuriesBlock.propTypes = {
    image: PropTypes.string,
    header: PropTypes.string,
    body: PropTypes.array,
}
