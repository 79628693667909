import React from 'react'
import PropTypes from "prop-types";
import classNames from "classnames"

import {makeStyles} from "@material-ui/core/styles"
import Button from "@material-ui/core/Button";

const useStyles = makeStyles(theme => ({
    button: {
        width: "160px",
        boxShadow: theme.shadows[0],
        borderRadius: theme.shape.borderRadius,
        padding: "12px 30px",
        // margin: "4px 1px",
        margin: "0",
        textTransform: "uppercase",
        willChange: "box-shadow, transform",
        textAlign: "center",
        whiteSpace: "nowrap",
        touchAction: "manipulation",
        textDecoration: "none!important",
    },
    dark: {
        "&,&:focus,&:hover": {
            color: theme.palette.primary.main,
            backgroundColor: theme.palette.grey["800"]
        },
        "&:hover,&:focus": {
            color: theme.palette.primary.main,
            backgroundColor: theme.palette.grey["300"],
            boxShadow: theme.shadows[2],
        }
    },
    green: {
        "&,&:focus,&:hover": {
            color: theme.palette.common.white,
            backgroundColor: theme.palette.primary.main,
        },
        "&:hover,&:focus": {
            color: theme.palette.primary.main,
            backgroundColor: theme.palette.grey["300"],
            boxShadow: theme.shadows[2],
        }
    },
}))

const BlockButton = React.forwardRef((props, ref) => {
    const {children, color, className, ...rest} = props
    const classes = useStyles()

    const buttonClasses = classNames({
        [classes.button]: true,
        [classes.dark]: color === "dark",
        [classes.green]: color !== "dark",
        [className]: true
    })
    return (
        <Button {...rest} ref={ref} className={buttonClasses}>
            {children}
        </Button>
    )
})

BlockButton.defaultProps = {
    color: 'green',
};

BlockButton.propTypes = {
    children: PropTypes.node,
    color: PropTypes.oneOf([
        "green",
        "dark",
    ])
};

export default BlockButton
