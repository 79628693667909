import React from 'react';
import PropTypes from "prop-types";

import {makeStyles, Typography} from "@material-ui/core";

const useStyles = makeStyles({
    headerText: {
        textTransform: "uppercase",
    },
});

export default function ContactCard({children, header}) {
    const classes = useStyles()
    return (
        <>
            <Typography
                variant={"h4"}
                color={"primary"}
                align={"center"}
                className={classes.headerText}
                gutterBottom
            >
                {header}
            </Typography>
            {children}
        </>
    );
}

ContactCard.propTypes = {
    header: PropTypes.string.isRequired,
}