import React from 'react'
import PropTypes from 'prop-types'

import {makeStyles} from "@material-ui/core/styles"

import {email as moveWellEmail} from "../../assets/data/global";
import classNames from "classnames";

const useStyles = makeStyles(theme => ({
    text: {
        textDecoration: "none",
        "&:hover": {
            textDecoration: "underline",
        }
    },
    blackText: {
        color: "inherit",
    }
}))

export default function EmailLink({text, colored, email}) {
    const classes = useStyles()
    const linkClasses = classNames({
        [classes.text]: true,
        [classes.blackText]: !colored
    })
    return (
        <a
            href={"mailto:"+email}
            rel={"noreferrer noopener"}
            className={linkClasses}
        >
            {text}
        </a>
    )
}

EmailLink.defaultProps = {
    colored: false,
    email: moveWellEmail
}

EmailLink.propTypes = {
    text: PropTypes.string.isRequired,
    email: PropTypes.string,
    colored: PropTypes.bool,
}
