import React from "react";
import PropTypes from "prop-types"

import HeaderButton from "./HeaderButton";
import HeaderDropdown from "../Dropdown/HeaderDropdown";

export default function HeaderLink({element, onClick, dropPlacement, vertical}) {
    if (element.sub === undefined) return (
        <HeaderButton element={element} onClick={onClick} fullWidth />
    )

    return (
        <HeaderDropdown
            noLiPadding
            navDropdown
            buttonText={element.core.key}
            dropdownList={element.sub}
            dropPlacement={dropPlacement}
            fullWidth
            element={element}
            vertical={vertical}
            onClick={onClick}
        />
    )
}

HeaderLink.defaultProps = {
    dropPlacement: "bottom-end",
}

HeaderLink.propTypes = {
    dropPlacement: PropTypes.oneOf([
        "bottom",
        "top",
        "right",
        "left",
        "bottom-start",
        "bottom-end",
        "top-start",
        "top-end",
        "right-start",
        "right-end",
        "left-start",
        "left-end"
    ]),
    element: PropTypes.object.isRequired,
    onClick: PropTypes.func,
}
