import React, {useContext, createContext} from "react";
import {useScrollTrigger} from "@material-ui/core";

const ScrollTriggerContext = createContext();

// Hook that enables any component to subscribe to scroll trigger state
export const useCustomScrollTrigger = () => useContext(ScrollTriggerContext)

export default function ScrollTriggerProvider({children}) {
    const trigger = useScrollTrigger({ threshold: 15, disableHysteresis: true });
    return <ScrollTriggerContext.Provider value={{trigger}}>{children}</ScrollTriggerContext.Provider>;
}
