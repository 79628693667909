/*Company Information*/
const companyName = "MoveWell"
const headerString = `${companyName} Physiotherapy`

const address1 = "181 Bay Street"
const address2 = "Brighton, VIC, 3186"

const phone = "+61482176150"
const phone_string = "(+61) 0482 176 150"
const email = "hello@movewellmelbourne.com"

/* Landing Carousel Backgrounds */
const image1 = "/physio/layout_1.jpg";
const image2 = "/physio/clients/client.jpg";
const image3 = "/physio/clients/client_18.jpg";
const image4 = "/physio/clients/client_29.jpg";

/* Main Page Backgrounds */
const AboutBackground = "/physio/sign.jpg";
const TestimonialBackground = "/physio/layout_1.jpg";
const ContactBackground = "/physio/reception.jpg";
const EmployeeProfileBackground = "/physio/sign.jpg";

/* Services Page Backgrounds */
const PhysiotherapyBackground = "/physio/clients/client_26.jpg";
const PhysioLedRehabBackground = "/physio/clients/client_3.jpg";
const FocusedGroupBackground = "/physio/clients/client_39.jpg";

export {
    headerString,

    companyName,
    address1,
    address2,
    phone,
    phone_string,
    email,

    image1,
    image2,
    image3,
    image4,

    AboutBackground,
    TestimonialBackground,
    ContactBackground,
    EmployeeProfileBackground,

    PhysiotherapyBackground,
    PhysioLedRehabBackground,
    FocusedGroupBackground,
}
