import React from "react";

import {makeStyles, Typography} from "@material-ui/core";

import MapContainer from "../../components/MapContainers/MapContainer";
import ContactCard from "../../components/Card/ContactCard";

import {ContactData} from "../../assets/data/pages/contact";

const useStyles = makeStyles(theme => ({
    mapContainer: {
        margin: theme.spacing(2, "auto"),
        position: "relative",
        height: "35vh",
        width: "100%",
        maxWidth: "600px",
    },
}));

export default function Address() {
    const classes = useStyles();
    return (
        <ContactCard header={ContactData.address.header}>
            <Typography
                variant={"body1"}
                color={"textPrimary"}
                align={"center"}
            >
                {ContactData.address.address1}
            </Typography>
            <Typography
                variant={"body1"}
                color={"textPrimary"}
                align={"center"}
                gutterBottom
            >
                {ContactData.address.address2}
            </Typography>
            <Typography
                variant={"body1"}
                color={"textPrimary"}
                align={"center"}
            >
                {ContactData.address.hint}
            </Typography>
            <div className={classes.mapContainer}>
                <MapContainer defaultProps={ContactData.address.map.defaultProps}/>
            </div>
        </ContactCard>
    )
}
