import React from "react";

import DefaultPageLayout from "../../layouts/DefaultPageLayout";
import ParallaxLayout from "../../components/Parallax/Parallax";
import ColumnLayout from "../../layouts/ColumnLayout";

import GreenHeaderBlackBody from "../../components/Containers/GreenHeaderBlackBody";
import Section from "../../components/Containers/Section";
import BookingButtonsGroup from "../../components/Buttons/BookingButtonsGroup";
import BodyHeaderText from "../../components/Containers/BodyHeaderText";

import {PhysioLedRehabData} from "../../assets/data/pages/services/physioLedRehab";

export default function PhysioLedRehabPage() {
    return (
        <DefaultPageLayout>
            <ParallaxLayout
                parallaxImage={PhysioLedRehabData.core.parallaxImage}
                parallaxHeader={PhysioLedRehabData.core.parallaxHeader}
                parallaxBody={PhysioLedRehabData.core.parallaxBody}
            />
            <ColumnLayout>
                <Section top>
                    <GreenHeaderBlackBody
                        header={PhysioLedRehabData.top.header}
                        body={PhysioLedRehabData.top.body}
                    />
                </Section>
                <Section>
                    <GreenHeaderBlackBody
                        header={PhysioLedRehabData.bottom.header}
                    >
                        <BodyHeaderText body={PhysioLedRehabData.bottom.body} />
                    </GreenHeaderBlackBody>
                    <BookingButtonsGroup />
                </Section>
            </ColumnLayout>
        </DefaultPageLayout>
    )
}
