import React from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";

import {unstable_createMuiStrictModeTheme as createMuiTheme, CssBaseline, responsiveFontSizes, useMediaQuery} from "@material-ui/core";
import {ThemeProvider} from "@material-ui/core/styles";

import LandingPage from "./pages/LandingPage";
import AboutPage from "./pages/AboutPage";
import ContactPage from "./pages/ContactPage";
import NotFoundPage from "./pages/NotFoundPage";

import PhysiotherapyPage from "./pages/services/PhysiotherapyPage";
import PhysioLedRehabPage from "./pages/services/PhysioLedRehabPage";
import EmployeeProfilePage from "./pages/EmployeeProfilePage";

import {URLS} from "./assets/strings/urls";

import '@fontsource/roboto';
import ScrollTriggerProvider from "./components/Providers/ScrollTriggerProvider";

export default function App() {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const theme = React.useMemo( () => responsiveFontSizes(createMuiTheme({
    palette: {
      type: prefersDarkMode ? 'dark' : 'light',
      primary: {
        light: '#7ec772',
        main: '#4D9645',
        dark: '#196719',
      },
    },
    drawer: {
      width: 320
    },
    spacing: factor => `${0.5 * factor}rem`, // (Bootstrap strategy)
  })), [prefersDarkMode]);

  return (
      <ThemeProvider theme={theme}>
        <ScrollTriggerProvider>
          <CssBaseline />
          <Router>
            <Switch>
              {/* Page Routes */}
              <Route
                  exact
                  path={URLS.ROOT}
                  component={LandingPage}
              />
              <Route
                  exact
                  path={URLS.PHYSIOTHERAPY}
                  component={PhysiotherapyPage}
              />
              <Route
                  exact
                  path={URLS.PHYSIO_LEAD_REHAB}
                  component={PhysioLedRehabPage}
              />
              <Route
                  exact
                  path={URLS.ABOUT}
                  component={AboutPage}
              />
              <Route
                  exact
                  path={URLS.CONTACT}
                  component={ContactPage}
              />
              <Route
                  exact
                  path={URLS.ERROR}
                  component={NotFoundPage}
              />
              <Route
                  path={`${URLS.PROFILE}/:slug`}
                  component={EmployeeProfilePage}
              />

              {/* Default Route */}
              <Route>
                <Redirect to={URLS.ERROR}/>
              </Route>
            </Switch>
          </Router>
        </ScrollTriggerProvider>
      </ThemeProvider>
  );
}
