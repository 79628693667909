import React from "react";

import HalfHorizontalTexts from "../../components/Containers/HalfHorizontalTexts";
import ContactCard from "../../components/Card/ContactCard";

import {ContactData} from "../../assets/data/pages/contact";

export default function Contact() {
    return (
        <ContactCard header={ContactData.contact.header}>
            <HalfHorizontalTexts
                type={"phone"}
                leftText={ContactData.contact.telephone.header}
                rightText={ContactData.contact.telephone.value}
            />
            <HalfHorizontalTexts
                type={"email"}
                leftText={ContactData.contact.email.header}
                rightText={ContactData.contact.email.data}
                bottomGutter={false}
            />
        </ContactCard>
    )
}
