import React from "react";
import {Link} from "react-router-dom";
import classNames from "classnames";
import PropTypes from "prop-types"

import {makeStyles} from "@material-ui/core/styles";
import {Typography} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";

import BodyText from "../../components/Containers/BodyText";

const useStyles = makeStyles(theme => ({
    rightAlign: {
        textAlign: "right!important",
    },
    bodyHeaderText: {
        textTransform: "uppercase",
        marginBottom: theme.spacing(2),
    },
    bodyParaText: {
        [theme.breakpoints.down('sm')]: {
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(2),
            marginLeft: 0,
            marginRight: 0,
        },
        [theme.breakpoints.up('md')]: {
            marginTop: theme.spacing(3),
            marginBottom: theme.spacing(3),
            marginLeft: 0,
            marginRight: 0,
        }
    },
    bodyActionText: {
        marginTop: theme.spacing(2),
        textTransform: "uppercase",
        "&:hover": {
            color: theme.palette.primary.light,
        },
    },
    image: {
        width: "100%",
        height: "auto",
        position: "relative",
        marginRight: "-40vw",
        backgroundPosition: "50%",
        backgroundSize: "cover",
        padding: "0",
        border: "0",
        display: "flex",
        alignItems: "center",
        borderRadius: theme.shape.borderRadius,
        boxShadow: theme.shadows[4]
    },
    leftPad: {
        [theme.breakpoints.down("sm")]: {
            padding: "1em 0",
        },
        [theme.breakpoints.up("md")]: {
            padding: "2em 2em 2em 0",
        }
    },
    rightPad: {
        [theme.breakpoints.down("sm")]: {
            padding: "1em 0",
        },
        [theme.breakpoints.up("md")]: {
            padding: "2em 0 2em 2em",
        }
    },
    icon: {
        verticalAlign: "middle"
    },
    link: {
        textDecoration: 'none',
    },
}));

export default function ParaLayout(props) {
    const classes = useStyles();
    const {image, headerString, bodyStringArray, actionString, actionUrl, imageLeft} = props

    const TextBlock = ({imageLeft}) => (
        <>
            <Typography
                variant={"h4"}
                component={"h3"}
                color={"primary"}
                className={classNames(classes.bodyHeaderText, imageLeft && classes.rightAlign)}
            >
                {headerString}
            </Typography>
            <BodyText
                body={bodyStringArray}
                className={imageLeft && classes.rightAlign}
            />
            <Link to={actionUrl} className={classes.link}>
                <Typography
                    variant={"h5"}
                    component={"h4"}
                    color={"textSecondary"}
                    className={classes.bodyActionText}
                    align={imageLeft ? "right" : "left"}
                >
                    {actionString}<KeyboardArrowRightIcon className={classes.icon}/>
                </Typography>
            </Link>
        </>
    )

    const ImageBlock = (alt) => (
        <img
            className={classes.image}
            src={image}
            alt={alt}
        />
    )

    return (
        <Grid
            container
            direction="row"
            alignItems="center"
        >
            <Grid item xs={12} sm={12} md={6} className={classes.leftPad}>
                {imageLeft === true
                    ? <ImageBlock alt={headerString}/>
                    : <TextBlock imageLeft={imageLeft}/>
                }
            </Grid>
            <Grid item xs={12} sm={12} md={6} className={classes.rightPad}>
                {imageLeft === false
                    ? <ImageBlock alt={headerString}/>
                    : <TextBlock imageLeft={imageLeft}/>
                }
            </Grid>
        </Grid>
    )
}

ParaLayout.propTypes = {
    image: PropTypes.string,
    headerString: PropTypes.string,
    bodyStringArray: PropTypes.array,
    actionString: PropTypes.string,
    actionUrl: PropTypes.string,
    imageLeft: PropTypes.bool,
}
