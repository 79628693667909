import {EmployeeProfileBackground} from "../global"
import {EmployeeProfiles} from "../employees";

const EmployeeProfilePageData = {
    core: {
        parallaxImage: EmployeeProfileBackground,
        parallaxHeader: "Staff Profile",
        parallaxBody: "",
    },
    employees: EmployeeProfiles
}

export {
    EmployeeProfilePageData
}
