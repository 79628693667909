import React from "react";

import {makeStyles, useTheme} from "@material-ui/core/styles";
import {Grid, Typography, useMediaQuery} from "@material-ui/core";

import ColumnLayout from "../../layouts/ColumnLayout";
import SocialButtonGroup from "../Buttons/SocialButtonGroup";

import {FooterData} from "../../assets/data/components/footer";

const useStyles = makeStyles(theme => ({
    leftItem: {
        marginTop: "auto",
        marginBottom: "auto",
        justifyContent: "flex-start",
        [theme.breakpoints.down('sm')]: {
            padding: "5vh 0",
        },
        [theme.breakpoints.up('md')]: {
            textAlign: "left",
        }
    },
    footer: {
        padding: "40px 0",
        backgroundColor: theme.palette.grey["900"],
        color: theme.palette.common.white,
        "& a": {
            color: theme.palette.common.white,
            opacity: ".86",
            "&:visited": {
                color: theme.palette.common.white
            },
            "&:focus, &:hover": {
                opacity: 1
            }
        },
    },
    centerItem: {
        [theme.breakpoints.down('sm')]: {
            padding: "5vh 0",
        },
        marginTop: "auto",
        marginBottom: "auto",
        justifyContent: "center",
    },
    rightItem: {
        [theme.breakpoints.down('sm')]: {
            padding: "5vh 0",
        },
        marginTop: "auto",
        marginBottom: "auto",
        justifyContent: "flex-end",
    },
    supporterLogo: {
        height: "80px",
        width: "auto",
        display: "inline",
        marginLeft: "10px",
        marginRight: "10px",
        [theme.breakpoints.down('sm')]: {
            marginLeft: "0",
            marginRight: "20px",
        },
    },
    supporterLogoContainer: {
        textAlign: "center",
        marginLeft: "auto",
        marginRight: "auto",
        [theme.breakpoints.down('sm')]: {
            textAlign: "left",
        },
    }
}))

export default function Footer() {
    const classes = useStyles();
    const theme = useTheme()
    const isSmDown = useMediaQuery(theme.breakpoints.down("sm"))
    return (
        <footer className={classes.footer}>
            <ColumnLayout>
                <Grid
                    container
                    direction={"row"}
                    justify={"space-between"}
                    alignContent={"center"}
                >
                    <Grid item xs={12} sm={12} md={4} className={classes.leftItem}>
                        <Typography
                            variant={"subtitle1"}
                            component={"p"}
                            color={"primary"}
                            gutterBottom={true}
                        >
                            {FooterData.core.header}
                        </Typography>
                        <Typography
                            variant={"subtitle2"}
                            component={"p"}
                        >
                            {FooterData.core.address1}
                        </Typography>
                        <Typography
                            variant={"subtitle2"}
                            component={"p"}
                        >
                            {FooterData.core.address2}
                        </Typography>
                    </Grid>
                    <Grid item sm={6} md={4} className={classes.centerItem}>
                        <Typography
                            variant={"subtitle1"}
                            component={"p"}
                            align={isSmDown ? "left" : "center"}
                            gutterBottom
                        >
                            {FooterData.middle.header}
                        </Typography>
                        <div className={classes.supporterLogoContainer}>
                            {FooterData.middle.supporters.map(value =>
                                <img
                                    key={value.name}
                                    src={value.logo}
                                    alt={value.name}
                                    className={classes.supporterLogo}
                                />
                            )}
                        </div>
                    </Grid>
                    <Grid item sm={6} md={4} className={classes.rightItem}>
                        <SocialButtonGroup justify={"flex-end"} />
                    </Grid>
                </Grid>
            </ColumnLayout>
        </footer>
    )
}
