import React from "react";
import PropTypes from "prop-types"

import {makeStyles} from "@material-ui/core/styles";
import {Typography} from "@material-ui/core";

import BodyText from "./BodyText";

const useStyles = makeStyles(theme => ({
    headerText: {
        textTransform: "uppercase",
    },
}));

export default function GreenHeaderBlackBody({header, body, children}) {
    const classes = useStyles();
    return (
        <>
            <Typography
                variant={"h4"}
                color={"primary"}
                align={"center"}
                className={classes.headerText}
                gutterBottom
            >
                {header}
            </Typography>
            {body && (
                <BodyText
                    body={body}
                />
            )}
            {children && children}
        </>
    )
}

GreenHeaderBlackBody.propTypes = {
    header: PropTypes.string,
    body: PropTypes.array,
}
