import React from "react";
import PropTypes from "prop-types";

import {Grid, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";

import {AboutData} from "../../assets/data/pages/about";
import EmployeeProfileCardSmall from "../../components/Card/EmployeeProfileCardSmall";

const useStyles = makeStyles({
    headerText: {
        textTransform: "uppercase",
    },
});

export default function BottomProfiles(props) {
    const classes = useStyles();

    return(
        <>
            <Typography
                variant={"h3"}
                color={"primary"}
                align={"center"}
                className={classes.headerText}
                gutterBottom
            >
                {AboutData.employee.header}
            </Typography>
            <Grid
                container
                justify={"center"}
                alignContent={"center"}
            >
                {AboutData.employee.employees.map((element, key) =>
                    <Grid item xs={12} sm={5} md={5} key={key}>
                        <EmployeeProfileCardSmall employee={element}/>
                    </Grid>
                )}
            </Grid>
        </>
    )
}

EmployeeProfileCardSmall.propTypes = {
    employee: PropTypes.any
};
