import React from 'react'
import PropTypes from "prop-types"

import {makeStyles} from "@material-ui/core/styles"
import {Grid, IconButton} from "@material-ui/core";
import {Facebook, Instagram} from "@material-ui/icons";

const useStyles = makeStyles(theme => ({
    button: {
        width: "36px",
        height: "36px",
        color: theme.palette.text.primary,
    },
}))

export default function SocialButtonGroup({size, justify}) {
    const classes = useStyles()
    return (
        <Grid container justify={justify}>
            <Grid item>
                <IconButton
                    href="https://www.facebook.com/movewellmelbourne"
                    aria-label="facebook-link"
                    target="_blank"
                    rel="noopener noreferrer"
                    className={classes.button}
                    size={size}
                >
                    <Facebook />
                </IconButton>
            </Grid>
            <Grid item>
                <IconButton
                    href="https://www.instagram.com/movewellmelbourne/"
                    aria-label="instagram-link"
                    target="_blank"
                    rel="noopener noreferrer"
                    className={classes.button}
                    size={size}
                >
                    <Instagram />
                </IconButton>
            </Grid>
        </Grid>
    )
}

SocialButtonGroup.defaultProps = {
    size: "small",
    justify: "center",
}

SocialButtonGroup.propTypes = {
    justify: PropTypes.string,
    size: PropTypes.oneOf([
        "small",
        "medium",
        "large",
    ]),
}
