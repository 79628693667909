import React from "react"
import Carousel from "react-material-ui-carousel";

import {Link, Typography, makeStyles} from "@material-ui/core"

import GreenHr from "../Divider/GreenHr";
import ButtonGroup from "../Buttons/ButtonGroup";
import BookConsultButton from "../Buttons/BookConsultButton";
import BlockButton from "../Buttons/BlockButton";
import BookingButtonsGroup from "../Buttons/BookingButtonsGroup";

import {LandingData} from "../../assets/data/pages/landing-data";

import {hexToRgb} from "../../util/hexToRgb";

const useStyles = makeStyles(theme => ({
    filter: {},
    imageTint: {
        backgroundSize: "contain",
        "&:before": {
            background: `rgba(${hexToRgb(theme.palette.common.black)}, 0.5)`
        },
        "&:after,&:before": {
            position: "absolute",
            zIndex: "1",
            width: "100vw",
            height: "115vh",
            display: "block",
            left: "0",
            top: "0",
            content: "''"
        }
    },
    imageText: {
        position: "absolute",
        height: "115vh",
        width: "100vw",
        zIndex: "10",
        top: "42vh",
        left: 0,
        textAlign: "center",
        verticalAlign: "middle",
        display: "block!important",
    },
    title: {
        color: theme.palette.common.white,
        textDecoration: "none",
        textTransform: "uppercase",
    },
    imageStyle: {
        backgroundSize: "cover",
        backgroundPosition: "top center",
        height: "115vh",
        maxHeight: "1600px",
        minWidth: "100%",
        position: "relative",
        margin: "0",
        padding: "0",
        border: "0",
        display: "flex",
        alignItems: "center",
    },
}));

export default function LandingPageCarousel() {
    const classes = useStyles();
    const settings = {
        indicators: false,
        autoplay: true,
        stopAutoPlayOnHover: false,
        navButtonsAlwaysInvisible: true,
        timeout: 1500,
        interval: 60000,
    };

    function Buttons(href, buttonText) {
        if (href !== "landing") {
            return (
                <ButtonGroup
                    leftButton={
                        <Link href={href}>
                            <BlockButton color={"green"}>
                                <Typography variant={"button"} >
                                    <strong>
                                        {buttonText}
                                    </strong>
                                </Typography>
                            </BlockButton>
                        </Link>
                    }
                    rightButton={
                        <BookConsultButton color={"dark"}/>
                    }
                />
            )
        } else {
            return (
                <BookingButtonsGroup />
            )
        }
    }

    function Slide(image, header, body, href, buttonText) {
        return (
            <div className={classes.imageTint}>
                <div style={{backgroundImage: `url(${image})`}} className={classes.imageStyle}/>
                <div className={classes.imageText}>
                    <Typography
                        variant={"h3"}
                        component={"h1"}
                        className={classes.title}
                    >
                        <strong>
                            {header}
                        </strong>
                    </Typography>
                    <GreenHr />
                    {body && (
                        <Typography
                            variant={'h5'}
                            component={"h2"}
                            className={classes.title}
                        >
                            {body}
                        </Typography>
                    )}
                    {Buttons(href, buttonText)}
                </div>
            </div>
        )
    }

    return (
        <Carousel {...settings}>
            {LandingData.carousel.map(element =>
                <React.Fragment key={element.header}>
                    {Slide(element.image, element.header, element.body, element.href, element.buttonText)}
                </React.Fragment>
            )}
        </Carousel>
    )
}
