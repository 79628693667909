import React from 'react';
import PropTypes from 'prop-types';
import classNames from "classnames";

import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    sectionTop: {
        [theme.breakpoints.down('sm')]: {
            paddingTop: theme.spacing(4)
        },
        [theme.breakpoints.up('md')]: {
            paddingTop: theme.spacing(8)
        }
    },
    sectionBottom: {
        [theme.breakpoints.down('sm')]: {
            paddingBottom: theme.spacing(4)
        },
        [theme.breakpoints.up('md')]: {
            paddingBottom: theme.spacing(8),
        }
    },
}));

export default function Section({children, top}) {
    const classes = useStyles()
    const sectionClasses = classNames({
        [classes.sectionTop]: true,
        [classes.sectionBottom]: !top,
    })
    return (
        <section className={sectionClasses}>
            {children}
        </section>
    );
}
Section.defaultProps = {
    top: false
};

Section.propTypes = {
    children: PropTypes.node.isRequired,
    top: PropTypes.bool
}
