import {URLS} from "../../strings/urls";

const HeaderData = {
    bannerText: "Phone: ",
    links: [
        {
            core: {
                key: "Home",
                link: URLS.ROOT,
                as: URLS.ROOT,
            },
        },
        {
            core: {
                key: "Services",
            },
            sub: [
                {
                    core: {
                        key: "PHYSIOTHERAPY",
                        link: URLS.PHYSIOTHERAPY,
                    },
                },
                {
                    core: {
                        key: "PHYSIO-LED REHABILITATION CLASSES",
                        link: URLS.PHYSIO_LEAD_REHAB,
                    },
                },
            ]
        },
        {
            core: {
                key: "About",
                link: URLS.ABOUT,
            },
        },
        {
            core: {
                key: "Book",
            },
            sub: [
                {
                    core: {
                        key: "Consults",
                        href: URLS.CLINIKO,
                    },
                },
                {
                    core: {
                        key: "Group Sessions",
                        href: URLS.CLINIKO,
                    },
                },
            ],
        },
        {
            core: {
                key: "Contact",
                link: URLS.CONTACT,
            },
        },
    ]
}

export {
    HeaderData
}
