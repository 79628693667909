import React from 'react';
import PropTypes from "prop-types";

import {Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import classNames from "classnames";

const useStyles = makeStyles(theme => ({
    body: {
        marginLeft: 0,
        marginRight: 0,
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    }
}));

export default function BodyText({body, className}) {
    const classes = useStyles()
    return (
        <>
            {body.map(element =>
                <Typography
                    key={element}
                    variant={"body2"}
                    color={"textPrimary"}
                    className={classNames(classes.body, className)}
                >
                    {element}
                </Typography>
            )}
        </>
    );
}

BodyText.propTypes = {
    body: PropTypes.array.isRequired,
    className: PropTypes.node,
}
