import React from 'react';
import PropTypes from "prop-types";

import {Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import classNames from "classnames";

const useStyles = makeStyles(theme => ({
    body: {
        marginLeft: 0,
        marginRight: 0,
    },
    bottomSpacing: {
        [theme.breakpoints.down('sm')]: {
            marginBottom: theme.spacing(2),
        },
        [theme.breakpoints.up('md')]: {
            marginBottom: theme.spacing(3),
        }
    }
}));

export default function BodyHeaderText({body, className}) {
    const classes = useStyles()
    return (
        <>
            {body.map((element, key) => {
                const rootClasses = classNames({
                    [classes.body]: true,
                    [className]: className,
                    [classes.bottomSpacing]: body.length - 1 !== key
                })
                return (
                    <span className={rootClasses} key={element.header}>
                        <Typography
                            variant={"subtitle1"}
                            color={"textPrimary"}
                        >
                            {element.header}
                        </Typography>
                        <Typography
                            variant={"body1"}
                            color={"textSecondary"}
                        >
                            {element.body}
                        </Typography>
                    </span>
                )
            })}
        </>
    );
}

BodyHeaderText.propTypes = {
    body: PropTypes.array.isRequired,
    className: PropTypes.node,
}
