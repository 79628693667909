import React from "react";

import DefaultPageLayout from "../layouts/DefaultPageLayout";
import ParallaxLayout from "../components/Parallax/Parallax";
import ColumnLayout from "../layouts/ColumnLayout";
import BottomProfiles from "../pages-sections/about-page/BottomProfiles";
import Section from "../components/Containers/Section";
import PageHr from "../components/Divider/PageHr";
import BodyText from "../components/Containers/BodyText";

import {AboutData} from "../assets/data/pages/about";

export default function AboutPage() {
    return (
        <DefaultPageLayout>
            <ParallaxLayout
                parallaxImage={AboutData.core.parallaxImage}
                parallaxHeader={AboutData.core.parallaxHeader}
                parallaxBody={AboutData.core.parallaxBody}
            />
            <ColumnLayout>
                <Section>
                    <BodyText body={AboutData.top}/>
                </Section>
                <PageHr/>
                <Section>
                    <BottomProfiles />
                </Section>
            </ColumnLayout>
        </DefaultPageLayout>
    )
}
