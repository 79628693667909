import React from "react";

import ContactCard from "../../components/Card/ContactCard";
import HalfHorizontalTexts from "../../components/Containers/HalfHorizontalTexts";

import {ContactData} from "../../assets/data/pages/contact";

export default function OpeningHours() {
    const openTimeStringFormatter = (open, close) => `${open} - ${close}`
    return (
        <ContactCard header={ContactData.openingHours.header}>
            {Object.keys(ContactData.openingHours.days).map((value, key) => {
                return (
                    <React.Fragment key={value}>
                        <HalfHorizontalTexts
                            type={"text"}
                            leftText={ContactData.openingHours.days[value][0]}
                            rightText={openTimeStringFormatter(
                                ContactData.openingHours.days[value][1],
                                ContactData.openingHours.days[value][2]
                            )}
                            bottomGutter={Object.keys(ContactData.openingHours.days).length - 1 !== key}
                        />
                    </React.Fragment>
                )
            })}
        </ContactCard>
    )
}
