import React from "react";
import PropTypes from "prop-types"
import classNames from "classnames";

import {makeStyles} from "@material-ui/core/styles";

import HeaderLink from "./HeaderLink";
import {HeaderData} from "../../assets/data/components/header";

const useStyles = makeStyles({
  list: {
    listStyle: "none",
  },
  horizontal: {
    display: "flex",
    alignItems: "center",
  },
  vertical: {
    padding: "40px 10px"
  }
})

export default function HeaderLinks({vertical, dropPlacement}) {
  const classes = useStyles();
  const listClasses = classNames({
    [classes.list]: true,
    [classes.horizontal]: !vertical,
    [classes.vertical]: vertical,
  })

  return (
      <ul className={listClasses}>
        {HeaderData.links.map(element =>
            <li key={element.core.key}>
              <HeaderLink fullWidth vertical={vertical} element={element} key={Object.keys(HeaderData)[0]} dropPlacement={dropPlacement} />
            </li>
        )}
      </ul>
  );
}

HeaderLinks.defaultProp = {
  vertical: false,
}

HeaderLinks.propTypes = {
  vertical: PropTypes.bool,
}
