import React from 'react'
import PropTypes from 'prop-types'

import {makeStyles} from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
    container: {
        width: "100%",
        margin: "0 auto",
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        [theme.breakpoints.only('xs')]: {
            maxWidth: "576px",
        },
        [theme.breakpoints.only('sm')]: {
            maxWidth: "768px",
        },
        [theme.breakpoints.only('md')]: {
            maxWidth: "992px",
        },
        [theme.breakpoints.up('lg')]: {
            maxWidth: "1152px",
        }
    },
}))

export default function ColumnLayout({children}) {
    const classes = useStyles()
    return (
        <div className={classes.container}>
            {children}
        </div>
    )
}

ColumnLayout.propTypes = {
    children: PropTypes.node.isRequired,
}
