import React from "react";
import {useLocation} from "react-router-dom";
import PropTypes from "prop-types"
import classNames from "classnames";

import {makeStyles} from "@material-ui/core/styles";
import {Link, Typography, Button} from "@material-ui/core";

import {URLS} from "../../assets/strings/urls";

const useStyles = makeStyles(theme => ({
    button: {
        borderRadius: theme.shape.borderRadius,
        padding: "6px 30px",
        margin: "0 1px",
        textAlign: "center",
        verticalAlign: "middle",
        touchAction: "manipulation",
        borderTop: "5px solid transparent",
        borderBottom: "5px solid transparent",
        borderLeft: "1px solid transparent",
        borderRight: "1px solid transparent",
        cursor: "pointer",
        "&:hover": {
            textDecoration: 'none',
            backgroundColor: theme.palette.primary.main,
            boxShadow: theme.shadows[3],
            border: `1px solid ${theme.palette.grey['300']}`,
        },
    },
    underline: {
        borderBottom: `5px solid ${theme.palette.primary.main}`,
    },
    buttonTypography: {
        color: theme.palette.common.white,
        textTransform: "uppercase",
        textDecoration: 'none',
        "&:hover": {
            textDecoration: 'none',
        },
        "&:focus, &:active": {
            textDecoration: 'none',
        }
    }
}));

export default function HeaderButton({children, element, onClick, fullWidth, ...rest}) {
    const classes = useStyles();
    const location = useLocation()

    const buttonStyles = classNames({
        [classes.button]: true,
        [classes.underline]: ((element.core.link === location.pathname) || ((element.core.link === URLS.PHYSIOTHERAPY) && (location.pathname === (URLS.PHYSIOTHERAPY || URLS.PHYSIO_LEAD_REHAB))))
    })

    if (element.core.link !== undefined) return (
        <Link href={element.core.link} as={element.core.as}>
            <Button className={buttonStyles} fullWidth {...rest}>
                <Typography variant={"button"} className={classes.buttonTypography}>
                    {element.core.key}
                </Typography>
            </Button>
        </Link>
    )

    if (element.core.href !== undefined) return (
        <Button className={buttonStyles} fullWidth onClick={onClick} href={element.core.href} rel={"noopener"} {...rest}>
            <Typography variant={"button"} className={classes.buttonTypography}>
                    {element.core.key}
            </Typography>
        </Button>
    )

    return (
        <Button className={buttonStyles} fullWidth onClick={onClick} {...rest}>
            <Typography variant={"button"} className={classes.buttonTypography}>
                {children}
            </Typography>
        </Button>
    )
}

HeaderButton.propTypes = {
    children: PropTypes.node,
    element: PropTypes.object,
    onClick: PropTypes.func,
    fullWidth: PropTypes.bool,
}
