import React from 'react'
import PropTypes from 'prop-types'

import {makeStyles} from "@material-ui/core/styles"

import {phone} from "../../assets/data/global";

const useStyles = makeStyles({
    text: {
        color: "inherit!important",
        textDecoration: "none",
        "&:hover": {
            textDecoration: "underline",
        }
    },
})

export default function PhoneLink({text}) {
    const classes = useStyles()
    return <a href={"tel:"+phone} className={classes.text}>{text}</a>
}

PhoneLink.propTypes = {
    text: PropTypes.string.isRequired,
}
