import React from 'react'
import gsap from "gsap"

import {makeStyles} from "@material-ui/core/styles"
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import classNames from "classnames";
import {useCustomScrollTrigger} from "../Providers/ScrollTriggerProvider";

const useStyles = makeStyles(theme => ({
    iconStyle: {
        width: "40px",
        height: "40px",
        marginTop: "92vh",
        marginLeft: "auto",
        marginRight: "auto",
        position: "fixed",
        color: "transparent",
        left: 0,
        right: 0,
        zIndex: 1000,
    },
    hidden: {
        display: "none!important",
    },
}))

export default function BreathDownArrow() {
    const classes = useStyles()
    const {trigger} = useCustomScrollTrigger();
    let intro = React.useRef(null)

    const arrowClasses = classNames({
        [classes.iconStyle]: true,
        [classes.hidden]: trigger
    })

    const textIntro = elem => gsap.to(elem, {
        delay: 1,
        scale: 1.25,
        opacity: 1,
        repeat: -1,
        yoyo: true,
        color: "white",
        duration: 1.75,
    })

    React.useEffect(() => {
        textIntro(intro)
    }, [])

    return (
        <ArrowDownwardIcon
            ref={el => intro = el}
            className={arrowClasses}
        />
    );
}

Function.defaultProps = {}

Function.propTypes = {}
