import React from "react";
import PropTypes from "prop-types"

import {makeStyles} from "@material-ui/core/styles";
import {Card, CardActions, CardContent, Link, Typography} from "@material-ui/core";
import Button from "@material-ui/core/Button";

import CardHeader from "./CardHeader";
import {hexToRgb} from "../../util/hexToRgb";

const useStyles = makeStyles(theme =>({
    root: {
        background: "transparent",
        boxShadow: 'none',
        // some jss/css to make the cards look a bit better on Internet Explorer
        "@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)": {
            display: "inline-block !important"
        },
        [theme.breakpoints.down("xs")]: {
            width: "75%",
        },
        [theme.breakpoints.up("sm")]: {
            padding: theme.spacing(2),
        },
    },
    cardSection: {
        paddingLeft: "0",
        paddingRight: "0",
    },
    image: {
        width: "100%",
        height: "auto",
        background: `rgba(${hexToRgb(theme.palette.common.black)}, 0.5)`
    }
}));

export default function EmployeeProfileCardSmall({employee}) {
    const classes = useStyles();
    return (
        <Card className={classes.root}>
            <CardHeader image plain>
                <a
                    href={employee.link}
                >
                    <img
                        src={employee.image}
                        alt={`${employee.name}_headshot"`}
                        className={classes.image}

                    />
                </a>
            </CardHeader>
            <CardContent className={classes.cardSection}>
                <Typography
                    variant={"body1"}
                    color={"textPrimary"}
                >
                    {employee.name}
                </Typography>
                <Typography
                    variant={"body2"}
                    color={"textSecondary"}
                >
                    {employee.title}
                </Typography>
            </CardContent>
            <CardActions className={classes.cardSection}>
                <Link href={employee.link}>
                    <Button variant={"contained"} color={"primary"}>
                        <Typography variant={"button"}>
                            + View
                        </Typography>
                    </Button>
                </Link>
            </CardActions>
        </Card>
    )
}

EmployeeProfileCardSmall.propTypes = {
    employee: PropTypes.object.isRequired,
}
