import React from "react"
import PropTypes from "prop-types";
import classNames from "classnames";
import {Link} from "react-router-dom";

import {makeStyles} from "@material-ui/core/styles";
import {AppBar, Grid, Hidden, IconButton, Typography} from "@material-ui/core";
import {Menu} from "@material-ui/icons";

import PhoneLink from "../Links/Phone";
import SocialButtonGroup from "../Buttons/SocialButtonGroup";
import ColumnLayout from "../../layouts/ColumnLayout";
import HeaderLinks from "./HeaderLinks";

import {HeaderData} from "../../assets/data/components/header";
import {URLS} from "../../assets/strings/urls";
import {phone_string} from "../../assets/data/global";

import {useCustomScrollTrigger} from "../Providers/ScrollTriggerProvider";

const Logo = "/img/logos/move-well-logo.png"

const useStyles = makeStyles(theme => ({
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        backgroundColor: "transparent!important",
        boxShadow: "none",
    },
    navBar: {
        display: "flex",
        alignItems: "center"
    },
    dark: {
        backgroundColor: `${theme.palette.grey["900"]}!important`,
        boxShadow: theme.shadows[3],
    },
    socialBanner: {
        width: "100vw",
        backgroundColor: theme.palette.background.paper
    },
    phoneString: {
        padding: "0 12px"
    },
    logo: {
        height: "auto",
        width: "130px",
    },
    logoLink: {
        marginRight: "auto"
    },
    icon: {
        padding: "0"
    }
}));

export default function AppHeader({handleDrawerToggle}) {
    const classes = useStyles();
    const {trigger} = useCustomScrollTrigger();
    const appBarClasses = classNames({
        [classes.appBar]: true,
        [classes.dark]: trigger,
    });

    return (
        <AppBar
            position="fixed"
            className={appBarClasses}
        >
            <Grid
                container
                justify={"center"}
                alignItems={"center"}
                className={classes.socialBanner}
            >
                <Grid item>
                    <Typography className={classes.phoneString} color={"textPrimary"} variant={"subtitle1"}>
                        {HeaderData.bannerText}<PhoneLink text={phone_string}/>
                    </Typography>
                </Grid>
                <Grid item>
                    <SocialButtonGroup />
                </Grid>
            </Grid>
            <ColumnLayout>
                <nav className={classes.navBar}>
                    <Link to={URLS.ROOT} className={classes.logoLink}>
                        <img src={Logo} alt={'MoveWell PhysiotherapyPage Logo'} className={classes.logo}/>
                    </Link>
                    <Hidden smDown implementation="css">
                        <HeaderLinks/>
                    </Hidden>
                    <Hidden mdUp>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            onClick={handleDrawerToggle}
                            size={"medium"}
                            className={classes.icon}
                        >
                            <Menu />
                        </IconButton>
                    </Hidden>
                </nav>
            </ColumnLayout>
        </AppBar>
    )
}

AppHeader.propTypes = {
    handleDrawerToggle: PropTypes.func.isRequired,
};
