import React from 'react';
import PropTypes from 'prop-types';

import {makeStyles} from "@material-ui/core/styles";
import {Card, CardContent, Typography} from "@material-ui/core";

import CardHeader from "./CardHeader";
import {hexToRgb} from "../../util/hexToRgb";
import EmailLink from "../Links/Email";

const useStyles = makeStyles(theme => ({
    root: {
        background: "transparent",
        boxShadow: 'none',
        // some jss/css to make the cards look a bit better on Internet Explorer
        "@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)": {
            display: "inline-block !important"
        },
        [theme.breakpoints.up("sm")]: {
            padding: theme.spacing(2),
        },
    },
    cardSection: {
        paddingLeft: "0",
        paddingRight: "0",
    },
    image: {
        height: "auto!important",
        boxShadow: theme.shadows[4],
        "&:before": {
            background: `rgba(${hexToRgb(theme.palette.common.black)}, 0.5)`
        },
        [theme.breakpoints.down("xs")]: {
            width: "65%!important",
        },
        [theme.breakpoints.up("sm")]: {
            width: "45%!important",
        },
    },
    body: {
        marginTop: theme.spacing(2),
    }
}));

export default function EmployeeProfileCardLarge({profile}) {
    const classes = useStyles()
    return (
        <Card className={classes.root}>
            <CardHeader image plain>
                <img
                    src={profile.image}
                    alt={profile.name + "_headshot"}
                    className={classes.image}
                />
            </CardHeader>
            <CardContent className={classes.cardSection}>
                <Typography
                    variant={"subtitle1"}
                    color={"textPrimary"}
                >
                    {profile.name}
                </Typography>
                <Typography
                    variant={"subtitle2"}
                    color={"textSecondary"}
                >
                    {profile.title}
                </Typography>
                <Typography
                    variant={"body2"}
                    color={"textPrimary"}
                    className={classes.body}
                >
                    {profile.description}
                    {profile?.description1 && (
                        <>
                            <br/>
                            <br/>
                            {profile.description1}
                        </>
                    )}
                    {profile?.bullet?.heading && (
                        <>    
                            <br/>
                            <br/>
                            {profile.bullet.heading}
                            <ul>
                                {profile.bullet.points.map((point) => (
                                    <li>{point}</li>
                                ))}
                            </ul>
                            {profile.contact.text1}
                            <EmailLink
                                text={profile.contact.url1}
                                email={profile.contact.url1}
                                colored={true}
                            />
                            {profile.contact.text2}
                            <a
                                href={`https://${profile.contact.url2}`}
                                rel={"noreferrer noopener"}
                                target={"_blank"}
                            >
                                {profile.contact.url2}
                            </a>
                            {profile.contact.text3}
                        </>
                    )}
                </Typography>
            </CardContent>
        </Card>
    );
}

EmployeeProfileCardLarge.propTypes = {
    profile: PropTypes.object.isRequired,
};
