import React from 'react';

import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    hr: {
        borderColor: theme.palette.divider,
    },
}));

export default function PageHr() {
    const classes = useStyles()
    return <hr className={classes.hr}/>
}
