import React from 'react';
import PropTypes from 'prop-types';

import {Drawer, Hidden} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";

import HeaderLinks from "../AppBar/HeaderLinks";

const useStyles = makeStyles(theme => ({
    drawerPaper: {
        right: "0",
        height: "100vh",
        width: theme.drawer.width,
        boxShadow: theme.shadows[3],
        transition: `${theme.transitions.duration.enteringScreen} ${theme.transitions.easing.easeIn}`,
        backgroundColor: theme.palette.grey['900']
    },
}))

export default function AppDrawer({open, handleDrawerToggle}) {
    const classes = useStyles()
    return (
        <Hidden lgUp implementation="js">
            <Drawer
                variant={"temporary"}
                anchor={"right"}
                open={open}
                classes={{
                    paper: classes.drawerPaper
                }}
                onClose={handleDrawerToggle}
            >
                <HeaderLinks vertical dropPlacement={"left-start"}/>
            </Drawer>
        </Hidden>
    )
}

AppDrawer.propTypes = {
    open: PropTypes.bool.isRequired,
    handleDrawerToggle: PropTypes.func.isRequired,
};