import React from "react";
import PropTypes from "prop-types"
import classNames from "classnames";

import {makeStyles} from "@material-ui/core/styles"
import Grid from "@material-ui/core/Grid";
import {Typography} from "@material-ui/core";

import GreenHr from "../Divider/GreenHr";

import {hexToRgb} from "../../util/hexToRgb";

const useStyles = makeStyles(theme => ({
    parallax: {
        backgroundSize: "cover",
        backgroundPosition: "top center",
        height: "85vh",
        maxHeight: "1600px",
        minWidth: "100%",
        position: "relative",
        margin: "0",
        padding: "0",
        border: "0",
        display: "flex",
        alignItems: "center",
    },
    filter: {},
    darkColor: {
        "&:before": {
            background: "rgba(" + hexToRgb(theme.palette.common.black) + ", 0.5)"
        },
        "&:after,&:before": {
            position: "absolute",
            zIndex: "1",
            width: "100%",
            height: "100%",
            display: "block",
            left: "0",
            top: "0",
            content: "''"
        }
    },
    container: {
        color: theme.palette.common.white,
        position: "absolute",
        height: "85vh",
        width: "100vw",
        zIndex: "10",
        top: "37vh",
        left: 0,
        textAlign: "center",
        verticalAlign: "middle",
        display: "block!important",
    },
    title: {
        marginTop: "30px",
        minHeight: "32px",
        textTransform: "uppercase",
        textDecoration: "none",
    },
    titleSubHeading: {
        textTransform: "uppercase",
        textDecoration: "none",
        textAlign: "center",
        margin: "0",
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
}))

export default function Parallax({parallaxImage, parallaxHeader, parallaxBody}) {
    const classes = useStyles()
    const parallaxClasses = classNames({
        [classes.parallax]: true,
        [classes.darkColor]: true,
    });
    return (
        <div
            className={parallaxClasses}
            style={{
                backgroundImage: `url(${parallaxImage})`,
            }}
        >
            <div className={classes.container}>
                <Grid
                    container
                    direction={"column"}
                    justify={"center"}
                    alignContent={"center"}
                >
                    <Grid >
                        <Typography variant={"h3"} className={classes.title}>
                            {parallaxHeader}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid
                    container
                    direction={"column"}
                    justify={"center"}
                    alignContent={"stretch"}
                >
                    <Grid item>
                        <GreenHr />
                    </Grid>
                    <Grid item>
                        <Typography variant={'h5'} className={classes.titleSubHeading}>
                            {parallaxBody}
                        </Typography>
                    </Grid>
                </Grid>
            </div>
        </div>

    )
}

Parallax.propTypes = {
    parallaxImage: PropTypes.string,
    parallaxHeader: PropTypes.string,
    parallaxBody: PropTypes.string,
}
