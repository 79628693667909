import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";

import {makeStyles, useTheme} from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Paper from "@material-ui/core/Paper";
import Grow from "@material-ui/core/Grow";
import Popper from "@material-ui/core/Popper";

import HeaderButton from "../AppBar/HeaderButton";

const useStyles = makeStyles(theme => ({
  popperClose: {
    pointerEvents: "none",
    display: "none !important"
  },
  popperNav: {
    [theme.breakpoints.down("sm")]: {
      "&, &$popperResponsive": {
        position: "static !important",
        left: "unset !important",
        top: "unset !important",
        transform: "none !important",
        willChange: "none !important",
        "& > div": {
          boxShadow: "none !important",
          transition: "none !important",
          marginTop: "0px !important",
          marginBottom: "5px !important",
          padding: "0px !important"
        }
      }
    }
  },
  manager: {
    "& > div > button:first-child > span:first-child, & > div > a:first-child > span:first-child": {
      width: "100%"
    },
    color: "transparent",
  },
  target: {
    "& > button:first-child > span:first-child, & > a:first-child > span:first-child": {
      display: "inline-block"
    },
    "& $caret": {
      marginLeft: "0px"
    }
  },
  dropdown: {
    border: "0",
    boxShadow: theme.shadows[4],
    top: "100%",
    zIndex: "1000",
    minWidth: "160px",
    padding: " 0",
    margin: "0 0",
    textAlign: "left",
    listStyle: "none",
    backgroundColor: theme.palette.grey["900"],
  },
  menuList: {
    padding: "0"
  },
  popperResponsive: {
    zIndex: "1200",
    position: "absolute !important",
    [theme.breakpoints.down("sm")]: {
      zIndex: "1640",
      float: "none",
      width: "auto",
      marginTop: "0",
      backgroundColor: "transparent",
      border: "0",
      boxShadow: "none",
      color: "black"
    }
  },
  dropdownItem: {
    padding: "1px 0px",
    margin: "0 auto",
    position: "relative",
    display: "block",
    color: theme.palette.grey["900"],
  },
  dropdownDividerItem: {
    color: `${theme.palette.primary.light}!important`,
    backgroundColor: `${theme.palette.primary.light}!important`,
  },
  caret: {
    transition: "all 150ms ease-in",
    display: "inline-block",
    width: "0",
    height: "0",
    marginLeft: "4px",
    verticalAlign: "middle",
    borderTop: "4px solid",
    borderRight: "4px solid transparent",
    borderLeft: "4px solid transparent"
  },
  caretActive: {
    transform: "rotate(180deg)"
  },
  caretDropup: {
    transform: "rotate(180deg)"
  },
  noLiPadding: {
    padding: "0"
  },
  underline: {
    borderBottom: `5px solid ${theme.palette.primary.main}`,
  },
  buttonTypography: {
    color: theme.palette.common.white,
    textTransform: "uppercase",
    textDecorationStyle: 'unset!important',
    "&:hover": {
      textDecorationStyle: 'unset!important',
    }
  }
}));

export default function HeaderDropdown(props) {
  const classes = useStyles();
  const theme = useTheme()
  const {
    noLiPadding,
    navDropdown,
    buttonText,
    dropdownList,
    dropup,
    dropPlacement,
    element,
    vertical,
    onClick,
  } = props;

  /* State variables */
  const [anchorEl, setAnchorEl] = React.useState(null);

  /* State modifiers */
  const handleCloseMenu = () => setAnchorEl(null);

  /* Event handlers */
  const handleClick = event => {
    if (anchorEl && anchorEl.contains(event.target)) setAnchorEl(null);
    else setAnchorEl(event.currentTarget);
  };
  const handleClose = event => {
    if (anchorEl.contains(event.target)) return;
    setAnchorEl(null);
  };

  /*  Styles */
  const caretClasses = classNames({
    [classes.caret]: true,
    [classes.caretDropup]: dropup && !anchorEl,
    [classes.caretActive]: Boolean(anchorEl) && !dropup,
  })
  const dropdownItem = classNames({
    [classes.dropdownItem]: true,
    [classes.noLiPadding]: noLiPadding,
  })

  const dropDownMenu = (
      <MenuList role="menu" className={classes.menuList}>
        {vertical && <hr color={theme.palette.grey["600"]}/>}
        {dropdownList.map(prop =>
            <MenuItem
                key={`Dropdown_${prop.core.key}`}
                onClick={() => handleCloseMenu(prop)}
                className={dropdownItem}
            >
              <HeaderButton fullWidth element={prop} onClick={onClick}/>
            </MenuItem>
        )}
        {vertical && <hr color={theme.palette.grey["600"]}/>}
      </MenuList>
  );

  return (
      <div className={classes.innerManager}>
        <HeaderButton element={element} onClick={handleClick} fullWidth>
          {buttonText !== undefined ? buttonText : null}
          <b className={caretClasses} />
        </HeaderButton>
        <Popper
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            transition
            disablePortal
            placement={dropPlacement}
            className={classNames({
              [classes.popperClose]: !anchorEl,
              [classes.popperResponsive]: true,
              [classes.popperNav]: Boolean(anchorEl) && navDropdown
            })}
        >
          {() => (
              <Grow
                  in={Boolean(anchorEl)}
                  id="menu-list"
                  style={
                    dropup
                        ? { transformOrigin: "0 100% 0" }
                        : { transformOrigin: "0 0 0" }
                  }
              >
                <Paper className={classes.dropdown}>
                  <ClickAwayListener onClickAway={handleClose}>
                    {dropDownMenu}
                  </ClickAwayListener>
                </Paper>
              </Grow>
          )}
        </Popper>
      </div>
  );
}

HeaderDropdown.defaultProps = {
  dropup: false,
};

HeaderDropdown.propTypes = {
  buttonText: PropTypes.node,
  dropdownList: PropTypes.array,
  dropup: PropTypes.bool,
  dropPlacement: PropTypes.oneOf([
    "bottom",
    "top",
    "right",
    "left",
    "bottom-start",
    "bottom-end",
    "top-start",
    "top-end",
    "right-start",
    "right-end",
    "left-start",
    "left-end"
  ]),
  noLiPadding: PropTypes.bool,
  navDropdown: PropTypes.bool,
  vertical: PropTypes.bool,
  onClick: PropTypes.func,
};
