import React from 'react'
import {Redirect, useParams} from "react-router-dom";

import DefaultPageLayout from "../layouts/DefaultPageLayout";
import ParallaxLayout from "../components/Parallax/Parallax";
import ColumnLayout from "../layouts/ColumnLayout";

import Section from "../components/Containers/Section";
import EmployeeProfileCardLarge from "../components/Card/EmployeeProfileCardLarge";

import {EmployeeProfilePageData} from "../assets/data/pages/EmployeeProfiles";
import {URLS} from "../assets/strings/urls";

export default function EmployeeProfilePage() {
    const {slug} = useParams();
    const employeeProfile = EmployeeProfilePageData.employees.find(value => value.id === slug)

    if (!employeeProfile) return <Redirect to={URLS.ERROR}/>

    return (
        <DefaultPageLayout>
            <ParallaxLayout
                parallaxImage={EmployeeProfilePageData.core.parallaxImage}
                parallaxHeader={EmployeeProfilePageData.core.parallaxHeader}
                parallaxBody={EmployeeProfilePageData.core.parallaxBody}
            />
            <ColumnLayout>
                <Section>
                    <EmployeeProfileCardLarge profile={employeeProfile}/>
                </Section>
            </ColumnLayout>
        </DefaultPageLayout>
    )
}
