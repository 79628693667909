import React from 'react'

import ButtonGroup from "./ButtonGroup";
import BookConsultButton from "./BookConsultButton";
import BookGroupClassButton from "./BookGroupClassButton";

export default function BookingButtonsGroup() {
    return (
        <ButtonGroup
            leftButton={<BookConsultButton/>}
            rightButton={<BookGroupClassButton/>}
        />
    )
}
