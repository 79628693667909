import React from 'react'

import {makeStyles, useTheme} from "@material-ui/core/styles"
import {useMediaQuery} from "@material-ui/core";

import AppHeader from "../components/AppBar/AppHeader";
import AppDrawer from "../components/Drawer/AppDrawer";
import Footer from "../components/Footer/Footer";

const useStyles = makeStyles(theme => ({
    main: {
        backgroundColor: theme.palette.background.default,
        margin: "0",
    }
}))

export default function DefaultPageLayout({children}) {
    const classes = useStyles()
    const theme = useTheme()
    const isLargeUp = useMediaQuery(theme.breakpoints.up(theme.breakpoints.values.md))

    /* State variables */
    const [drawerOpen, setDrawerOpen] = React.useState(false);

    /* State modifiers */
    const handleDrawerToggle = () => setDrawerOpen(prevState => !prevState)
    const handleDrawerClose = () => setDrawerOpen(false)

    /* UseEffect */
    React.useEffect(() => handleDrawerClose(), [isLargeUp])
    React.useEffect(() => {
        window.scrollTo(0, 0);
        document.body.scrollTop = 0;
    }, []);

    return (
        <>
            <AppHeader
                handleDrawerToggle={handleDrawerToggle}
            />
            <AppDrawer
                open={drawerOpen}
                handleDrawerToggle={handleDrawerToggle}
            />
            <main className={classes.main}>
                {children}
            </main>
            <Footer/>
        </>
    )
}
