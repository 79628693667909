import React from 'react';
import PropTypes from 'prop-types';

import {makeStyles} from "@material-ui/core/styles";
import {Grid} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    root: {
        marginTop: theme.spacing(2),
    },
    spacing: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        // [theme.breakpoints.down('sm')]: {
        //     marginLeft: "2px",
        //     marginRight: "2px",
        // },
        // [theme.breakpoints.up('md')]: {
        //     marginLeft: theme.spacing(2),
        //     marginRight: theme.spacing(2),
        // },
    }
}))

export default function ButtonGroup({leftButton, rightButton}) {
    const classes = useStyles()
    return (
        <Grid
            container
            direction={"row"}
            justify={"center"}
            className={classes.root}
        >
            <Grid item className={classes.spacing}>
                {leftButton}
            </Grid>
            <Grid item className={classes.spacing}>
                {rightButton}
            </Grid>
        </Grid>
    )
}

ButtonGroup.propTypes = {
    leftButton: PropTypes.node.isRequired,
    rightButton: PropTypes.node.isRequired,
}
