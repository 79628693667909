import React from "react";
import GoogleMapReact from 'google-map-react';

import LocationOnIcon from '@material-ui/icons/LocationOn';

export default function MapContainer({defaultProps}) {
    const {center, zoom} = defaultProps

    return (
        <GoogleMapReact
            bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_API_KEY }}
            defaultCenter={center}
            defaultZoom={zoom}
        >
            <LocationOnIcon
                color={"primary"}
                lat={-37.90478176840745}
                lng={144.99831374868188}
                fontSize={"large"}
            />
        </GoogleMapReact>
    );
}
