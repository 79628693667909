import React from "react";
import PropTypes from "prop-types"

import {Grid, Typography} from "@material-ui/core";

import PhoneLink from "../Links/Phone";
import EmailLink from "../Links/Email";

export default function HalfHorizontalTexts({leftText, rightText, type, bottomGutter}) {
    return (
        <Grid
            container
            justify={"space-between"}
        >
            <Grid item>
                <Typography
                    variant={"body1"}
                    color={"textPrimary"}
                    align={"left"}
                    gutterBottom={bottomGutter}
                >
                    {leftText}:
                </Typography>
            </Grid>
            <Grid item>
                <Typography
                    variant={"body1"}
                    color={"textPrimary"}
                    align={"right"}
                    gutterBottom={bottomGutter}
                >
                    {type==="phone" && <PhoneLink text={rightText}/>}
                    {type==="email" && <EmailLink text={rightText}/>}
                    {type==="text" && rightText}
                </Typography>
            </Grid>
        </Grid>
    )
}

HalfHorizontalTexts.defaultProps = {
    type: "text",
    bottomGutter: true,
};

HalfHorizontalTexts.propTypes = {
    leftText: PropTypes.string.isRequired,
    rightText: PropTypes.string.isRequired,
    bottomGutter: PropTypes.bool,
    type: PropTypes.oneOf([
        "phone",
        "email",
        "text",
    ])
}
