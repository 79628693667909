import React from 'react'
import PropTypes from "prop-types";
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import {isMobile} from "react-device-detect"

import {makeStyles, useTheme} from "@material-ui/core/styles"
import {Typography} from "@material-ui/core";
import LooksOneIcon from '@material-ui/icons/LooksOne';
import LooksTwoIcon from '@material-ui/icons/LooksTwo';
import Looks3Icon from '@material-ui/icons/Looks3';
import Looks4Icon from '@material-ui/icons/Looks4';

import 'react-vertical-timeline-component/style.min.css';

const useStyles = makeStyles(theme => ({
    headerText: {
        textTransform: "uppercase",
    },
}));

export default function TimelineBlock(props) {
    const classes = useStyles()
    const theme = useTheme()
    const {header, body} = props

    return (
        <>
            <Typography
                variant={"h4"}
                color={"textPrimary"}
                align={"center"}
                gutterBottom
                className={classes.headerText}
            >
                {header}
            </Typography>
            <VerticalTimeline animate={!isMobile}>
                {body.map((ele, key) => {
                    let icon
                    switch (key) {
                        case 0:
                            icon = <LooksOneIcon/>
                            break
                        case 1:
                            icon = <LooksTwoIcon/>
                            break
                        case 2:
                            icon = <Looks3Icon/>
                            break
                        case 3:
                            icon = <Looks4Icon/>
                            break
                        default: break;
                    }

                    return (
                        <React.Fragment key={key}>
                            {key % 2 === 0 ? (
                                <VerticalTimelineElement
                                    className="vertical-timeline-element--work"
                                    contentStyle={{ backgroundColor: theme.palette.primary.main, color: theme.palette.common.white }}
                                    contentArrowStyle={{ borderRight: `7px solid ${theme.palette.primary.main}` }}
                                    iconStyle={{ background: theme.palette.grey['800'], color: theme.palette.common.white }}
                                    icon={icon}
                                    key={key}
                                >
                                    <Typography variant={"h6"}>
                                        {ele.header}
                                    </Typography>
                                    <Typography variant={"body1"}>
                                        {ele.body}
                                    </Typography>
                                </VerticalTimelineElement>
                            ) : (
                                <VerticalTimelineElement
                                    className="vertical-timeline-element--work"
                                    contentStyle={{ backgroundColor: theme.palette.grey['800'], color: theme.palette.common.white }}
                                    contentArrowStyle={{ borderRight: `7px solid ${theme.palette.grey['800']}` }}
                                    iconStyle={{ background: theme.palette.primary.main, color: theme.palette.common.white }}
                                    icon={icon}
                                    key={key}
                                >
                                    <Typography
                                        variant={"h6"}
                                        align={"right"}
                                    >
                                        {ele.header}
                                    </Typography>
                                    <Typography
                                        variant={"body1"}
                                        align={"right"}
                                    >
                                        {ele.body}
                                    </Typography>
                                </VerticalTimelineElement>
                            )}
                        </React.Fragment>
                    )
                })}
            </VerticalTimeline>
        </>
    )
}

TimelineBlock.propTypes = {
    header: PropTypes.string,
    body: PropTypes.array,
}
