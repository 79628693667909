import React from 'react'

import {makeStyles} from "@material-ui/core/styles"
import {Link, Typography} from "@material-ui/core";

import {URLS} from "../assets/strings/urls";

const useStyles = makeStyles(theme => ({
    root: {
        height: '100vh',
        width: '100vw',
        backgroundColor: theme.palette.background.paper,
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(6),
        },
        [theme.breakpoints.up('md')]: {
            padding: theme.spacing(12),
        }
    },
}))

export default function NotFoundPage() {
    const classes = useStyles()
    return (
        <main className={classes.root}>
            <Typography
                variant={"h2"}
                color={"textPrimary"}
                gutterBottom
            >
                404 - Page not found :(
            </Typography>
            <Typography
                variant={"h4"}
                color={"textSecondary"}
            >
                Return {' '}
                <Link color={"primary"} href={URLS.ROOT}>
                    home
                </Link>
            </Typography>
        </main>
    )
}
