import {address1, address2, headerString} from "../global";

const AJAXLogo = "/img/logos/AJAX.png"
const MaccabiLogo = "/img/logos/MacabbiCricket.jpg"

const FooterData = {
    core: {
        header: headerString,
        address1: address1,
        address2: address2,
    },
    middle: {
        header: "Supporters:",
        supporters: [
            {
                name: "AJAXLogo",
                logo: AJAXLogo,
            },
            {
                name: "MaccabiLogo",
                logo: MaccabiLogo,
            },
        ]
    }
}

export {
    FooterData
}
