import React from "react";

import DefaultPageLayout from "../layouts/DefaultPageLayout";
import ColumnLayout from "../layouts/ColumnLayout";
import Address from "../pages-sections/contact-page/Address";
import OpeningHours from "../pages-sections/contact-page/OpeningHours";
import Contact from "../pages-sections/contact-page/Contact";
import Parallax from "../components/Parallax/Parallax";
import Section from "../components/Containers/Section";

import {ContactData} from "../assets/data/pages/contact";

export default function ContactPage() {
    return (
        <DefaultPageLayout>
            <Parallax
                parallaxImage={ContactData.core.parallaxImage}
                parallaxHeader={ContactData.core.parallaxHeader}
            />
            <ColumnLayout>
                <Section top>
                    <OpeningHours />
                </Section>
                <Section top>
                    <Address />
                </Section>
                <Section>
                    <Contact />
                </Section>
            </ColumnLayout>
        </DefaultPageLayout>
    )
}