import React from 'react';

import {Box, Grid, makeStyles, Typography, useTheme, useMediaQuery} from "@material-ui/core";

import {hexToRgb} from "../../util/hexToRgb";

const useStyles = makeStyles(theme => ({
    instagramItem: {
        margin: theme.spacing(1),
        display: 'block',
        position: 'relative',
        aspectRatio: "1/1",
        [theme.breakpoints.down("xs")]: {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
            marginLeft: 0,
            marginRight: 0,
        },
    },
    instagramImg: {
        borderRadius: theme.shape.borderRadius,
        display: 'block',
        width: '100%',
        height: '100%',
        transition: '.25s',
        objectFit: 'cover',
    },
    instagramIcon: {
        borderRadius: theme.shape.borderRadius,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        zIndex: '100',
        padding: theme.spacing(3),
        color: theme.palette.common.white,
        overflow: "hidden",
        opacity: 0,
        "&:hover": {
            opacity: 1,
            background: `rgba(${hexToRgb(theme.palette.common.black)}, 0.5)`
        },
    },
}))

export default function InstagramFeed({token, counter}) {
    const classes = useStyles()
    const theme = useTheme()
    const isSmallUp = useMediaQuery(theme.breakpoints.only("sm"));
    const count = isSmallUp ? (counter - 1) : counter

    /* State variables */
    const [feeds, setFeeds] = React.useState([])
    const [isLoaded, setLoaded] = React.useState(false)
    const [isError, setIsError] = React.useState('')

    /* State modifiers */
    const handleLoadedSet = () => setLoaded(true)
    const handleIsErrorSet = () => setIsError(true)
    const handleIsErrorUnset = () => setIsError(false)
    const handleFeedsSet = (feeds) => setFeeds(feeds)

    /* UseEffect */
    React.useEffect(() => {
        handleIsErrorUnset()

        const url = `https://graph.instagram.com/me/media?fields=media_count,media_type,permalink,media_url,caption&&access_token=${token}`;
        fetch(url)
            .then(res => res.json())
            .then(data => {
                if(data.hasOwnProperty('error')) return handleIsErrorSet()

                handleFeedsSet(data.data)
            })
            .catch(error => {
                console.error('Error:', error);
                return handleIsErrorSet()
            })
            .finally(() => handleLoadedSet())

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (isError) {
        return (
            <Typography
                variant={"body2"}
                align={"center"}
                color={"error"}
            >
                invalid access token
            </Typography>
        )
    } else if (!isLoaded) {
        return (
            <Typography
                variant={"body2"}
                align={"center"}
            >
                Loading...
            </Typography>
        )
    } else {
        return (
            <Grid
                container
                justify={"space-between"}
                alignItems={"center"}
                alignContent={"center"}
            >
                {feeds && feeds.slice(0, count).map(feed => (
                    <Grid item xs={12} sm={6} md={4} key={feed.id}>
                        <div className={classes.instagramItem}>
                            <a
                                href={feed.permalink}
                                className="ig-instagram-link"
                                target="_blank"
                                rel="noreferrer"
                            >
                                {(feed.media_type === 'IMAGE' || feed.media_type === 'CAROUSEL_ALBUM')
                                    ? <img
                                        className={classes.instagramImg}
                                        src={feed.media_url}
                                        alt="description"
                                    />
                                    : <video
                                        src={feed.media_url}
                                        className={classes.instagramImg}
                                    />
                                }
                                <div className={classes.instagramIcon}>
                                    <Box fontStyle="italic">
                                        <Typography variant={"body2"}>
                                            {feed.caption}
                                        </Typography>
                                    </Box>
                                </div>
                            </a>
                        </div>
                    </Grid>
                ))}
            </Grid>
        );
    }
}
