import React from 'react'

import DefaultPageLayout from "../layouts/DefaultPageLayout";
import ColumnLayout from "../layouts/ColumnLayout";

import ParaLayout from "../pages-sections/landing-page/ParaLayout";

import LandingPageCarousel from "../components/Carousel/LandingPageCarousel";
import Section from "../components/Containers/Section";
import PageHr from "../components/Divider/PageHr";
import BreathDownArrow from "../components/Animated/BreathDownArrow";
import InstagramFeed from "../components/InstagramFeed/InstagramFeed";

import {LandingData} from "../assets/data/pages/landing-data";
import {URLS} from "../assets/strings/urls";

export default function LandingPage() {
    return (
        <DefaultPageLayout>
            <BreathDownArrow />
            <LandingPageCarousel/>
            <ColumnLayout>
                <Section>
                    <ParaLayout
                        image={LandingData.para1.image}
                        headerString={LandingData.para1.header}
                        bodyStringArray={LandingData.para1.body}
                        actionString={LandingData.para1.action}
                        actionUrl={URLS.PHYSIOTHERAPY}
                        imageLeft={false}
                    />
                </Section>
                <PageHr/>
                <Section>
                    <ParaLayout
                        image={LandingData.para2.image}
                        headerString={LandingData.para2.header}
                        bodyStringArray={LandingData.para2.body}
                        actionString={LandingData.para2.action}
                        actionUrl={URLS.ABOUT}
                        imageLeft={true}
                    />
                </Section>
                <PageHr/>
                <Section>
                    <InstagramFeed
                        token={process.env.REACT_APP_INSTAGRAM_API_KEY}
                        counter="9"
                    />
                </Section>
            </ColumnLayout>
        </DefaultPageLayout>
    )
}
