import React from 'react'

import {Typography} from "@material-ui/core";

import BlockButton from "./BlockButton";

import {URLS} from "../../assets/strings/urls";

const data = {
    buttonText: "Book A Class",
    href: URLS.CLINIKO,
}

export default function BookGroupClassButton() {
    return (
        <BlockButton color={"dark"} href={data.href}>
            <Typography variant={"button"} >
                <strong>
                    {data.buttonText}
                </strong>
            </Typography>
        </BlockButton>
    )
}
