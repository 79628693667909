import React from "react";

import ParallaxLayout from "../../components/Parallax/Parallax";
import ColumnLayout from "../../layouts/ColumnLayout";
import DefaultPageLayout from "../../layouts/DefaultPageLayout";

import TimelineBlock from "../../pages-sections/services/physiotherapy/TimelineBlock";
import CommonInjuriesBlock from "../../pages-sections/services/physiotherapy/CommonInjuriesBlock";

import BookConsultButton from "../../components/Buttons/BookConsultButton";
import Section from "../../components/Containers/Section";
import GreenHeaderBlackBody from "../../components/Containers/GreenHeaderBlackBody";

import {PhysiotherapyData} from "../../assets/data/pages/services/physiotherapy";

export default function PhysiotherapyPage() {
    return (
        <DefaultPageLayout>
            <ParallaxLayout
                parallaxImage={PhysiotherapyData.core.parallaxImage}
                parallaxHeader={PhysiotherapyData.core.parallaxHeader}
                parallaxBody={PhysiotherapyData.core.parallaxBody}
            />
            <ColumnLayout>
                <Section top>
                    <GreenHeaderBlackBody
                        header={PhysiotherapyData.howPhysioCanHelp.header}
                        body={PhysiotherapyData.howPhysioCanHelp.para}
                    />
                </Section>
                <Section top>
                    <TimelineBlock
                        header={PhysiotherapyData.timelineBlock.header}
                        body={PhysiotherapyData.timelineBlock.body}
                    />
                </Section>
                <Section top>
                    <CommonInjuriesBlock
                        image={PhysiotherapyData.commonInjuries.image}
                        header={PhysiotherapyData.commonInjuries.header}
                        body={PhysiotherapyData.commonInjuries.body}
                    />
                </Section>
                <Section>
                    <GreenHeaderBlackBody
                        header={PhysiotherapyData.howToGetStarted.header}
                        body={PhysiotherapyData.howToGetStarted.para}
                    />
                    <BookConsultButton />
                </Section>
            </ColumnLayout>
        </DefaultPageLayout>
    )
}
