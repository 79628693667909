import { URLS } from "../strings/urls";

const YashaId = "yasha";
const MiaId = "mia";
const BrookeId = "brooke";


const YashaHeadshot = "/physio/team/headshot_yasha.jpg";
const MiaHeadshot = "/physio/team/headshot_mia.jpg";
const BrookeHeadshot = "/physio/team/headshot_brooke.jpg";

const EmployeeProfiles = [
    {
        id: YashaId,
        name: "Yasha Gurevich",
        title: "Principal Physiotherapist",
        description: "Yasha completed his Bachelor of Applied Science and Masters of Physiotherapy at La Trobe Bendigo. " +
            "Yasha’s clinical interest involve management of complex musculoskeletal conditions, sports physiotherapy and injury prevention. " +
            "Yasha has been involved in a variety of different sports settings including soccer, basketball and is currently the head physiotherapist at AJAX football club. " +
            "A passion for ongoing education and knowledge translation Yasha has delivered presentations on injury prevention to variety of different sports clubs and Lectures at La Trobe university on musculoskeletal physiotherapy. " +
            "With a strong focus on being patient centred, Yasha works closely with his patients to achieve their goals.",
        image: YashaHeadshot,
        link: URLS.PROFILE_YASHA,
    },
    {
        id: MiaId,
        name: "Mia Brayer",
        title: "Physiotherapist/Physiotherapy-led Instructor",
        description: "Mia graduated from La Trade University with a Bachelor of Applied Science and Masters of Physiotherapy Practice degree. Mia is passionate about providing a holistic approach towards helping people with pain and injuries return to what they love doing and achieve their goals. She has a keen interest in pain management, injury rehabilitation and prevention and has experience in treating a variety of conditions affecting clients of all ages and from all walks of life. Outside of work, Mia enjoys spending time outdoors, watching AFL and baking various goodies for her friends and family.",
        image: MiaHeadshot,
        link: URLS.PROFILE_MIA,
    },
    {
        id: BrookeId,
        name: "Brooke Roseman",
        title: "Physiotherapist",
        description: "Brooke graduated from Monash University with a Bachelor of Physiotherapy (Honours). She is a passionate physiotherapist who combines her love for movement with a deep commitment to helping others reach their goals. She has a strong interest in injury management and prevention to help people create sustainable improvements in their rehabilitation journey. Outside the clinic, Brooke loves spending time with her family and friends, hitting the netball court and finding the best new restaurant.",
        image: BrookeHeadshot,
        link: URLS.PROFILE_BROOKE,
    },
];

export {
    EmployeeProfiles,
};