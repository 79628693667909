import React from 'react'
import PropTypes from "prop-types"

import {Typography} from "@material-ui/core";

import BlockButton from "./BlockButton";

import {URLS} from "../../assets/strings/urls";

const data = {
    buttonText: "Book Consult",
    href: URLS.CLINIKO,
}

export default function BookConsultButton({color}) {
    return (
        <BlockButton color={color} href={data.href}>
            <Typography variant={"button"} >
                <strong>
                    {data.buttonText}
                </strong>
            </Typography>
        </BlockButton>
    )
}

BookConsultButton.defaultProps = {
    color: "green",
}

BookConsultButton.propTypes = {
    color: PropTypes.string,
}
